import Lightbox from 'stimulus-lightbox'
import lgHash from 'lightgallery/plugins/hash'
import lgAutoplay from 'lightgallery/plugins/autoplay'

export default class extends Lightbox {
  connect() {
    super.connect()
    this.lastViewedImage = null
  }

  get defaultOptions() {
    return {
      plugins: [
        lgHash,
        lgAutoplay
      ],
      slideShowInterval: 3000,
      galleryId: 'album',
      licenseKey: '601B4DFB-6A25-4B4E-8C36-ACC39F229121',
      resetScrollPosition: false,
      hideScrollbar: true
    }
  }

  onSlide(event) {
    const index = event.detail.index
    const target = event.target
    const child = target.children[index]
    const childTop = child.getBoundingClientRect().top
    const bodyTop = document.body.getBoundingClientRect().top
    const offset = Math.max(childTop - bodyTop - 100, 0)

    window.scrollTo({
      top: offset,
      left: 0,
      behavior: "smooth"
    })
  }
}
